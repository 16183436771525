<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div class="container mx-auto">
      <h1
        class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl py-2"
      >
        Scheduler
      </h1>

      <div class="mb-4">
        <!-- component -->
        <div class="mb-4">
          <div
            id=""
            class="p-4 bg-gray-50 rounded-md dark:bg-gray-800/25 border border-black/5 rounded dark:border-white/5"
          >
            <div>
              <h3 class="font-semibold">Manual Data Update</h3>
              <p
                class="font-medium text-sm text-gray-500 font-mono mb-3 dark:text-gray-400"
              >
                Button will be disabled before and after 1 hour of automatic
                scheduler
              </p>
              <button
                type="button"
                class="bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4"
                :class="
                  isManualUpdateDisabled || isManualBtnDisabled
                    ? 'cursor-not-allowed bg-opacity-75'
                    : 'bg-opacity-50 hover:bg-opacity-75'
                "
                :disabled="isManualUpdateDisabled || isManualBtnDisabled"
                @click="manualUpdate"
              >
                Click to manually update data
              </button>
              <br />
              <span
                :class="
                  isManualUpdateDisabled || isManualBtnDisabled ? '' : 'hidden'
                "
                class="font-medium text-xs text-red-500 font-mono mb-3 dark:text-red-400"
                >(Manual update is disabled)</span
              >
              <p
                class="font-medium text-sm text-gray-500 font-mono mb-3 dark:text-gray-400"
              >
                Manual updates only between 23:45 - 8:15 EST
              </p>
            </div>
            <div>
              <p
                class="font-medium text-sm text-gray-700 font-mono mt-12 dark:text-gray-400"
              >
                Current Timestamp(-04:00)-: <i>{{ currentDateTime }}</i>
              </p>
            </div>
          </div>
        </div>

        <table id="" class="border-collapse w-full text-sm">
          <thead>
            <tr>
              <th
                class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
              >
                Scheduler Type
              </th>
              <th
                class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
              >
                Start Time
              </th>
              <th
                class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
              >
                End Time
              </th>
              <th
                class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
              >
                Current Status
              </th>
            </tr>
          </thead>
          <tbody id="ticketsList">
            <tr
              v-if="!schedulerData.length"
              class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
            >
              <td
                class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                colspan="6"
              >
                {{ loading }}
              </td>
            </tr>
            <tr
              v-for="tr in schedulerData"
              :key="tr.id"
              class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0 capitalize"
            >
              <td
                class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
              >
                {{ tr.type }}
              </td>
              <td
                class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
              >
                {{ tr.starttime }}
              </td>
              <td
                class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
              >
                {{ tr.endtime }}
              </td>
              <td
                :class="
                  tr.isrunning == 1 ? 'text-green-800 italic' : 'text-gray-800'
                "
                class="w-full lg:w-auto p-3 text-center border border-b block md:table-cell relative lg:static"
              >
                {{ getStatus(tr.type, tr.isrunning, tr.endtime, tr.starttime) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";

const timeFormat = "H:mm";
const asMorningStartTime = "08:15";
const asMorningEndTime = "08:45";

// const asMorningStartTime0 = "09:15";
// const asMorningEndTime0 = "09:45";

// const asMorningStartTime1 = "10:15";
// const asMorningEndTime1 = "10:45";

// const asMorningStartTime2 = "11:15";
// const asMorningEndTime2 = "11:45";

// const asMorningStartTime3 = "12:15";
// const asMorningEndTime3 = "12:45";

// const asMorningStartTime4 = "13:15";
// const asMorningEndTime4 = "13:45";

// const asMorningStartTime5 = "14:15";
// const asMorningEndTime5 = "14:45";

// const asMorningStartTime6 = "15:15";
// const asMorningEndTime6 = "15:45";

// const asMorningStartTime7 = "16:15";
// const asMorningEndTime7 = "16:45";

// const asMorningStartTime8 = "17:15";
// const asMorningEndTime8 = "17:45";

// const asMorningStartTime9 = "18:15";
// const asMorningEndTime9 = "18:45";

// const asMorningStartTime10 = "19:15";
// const asMorningEndTime10 = "19:45";

// const asMorningStartTime11 = "20:15";
// const asMorningEndTime11 = "20:45";

// const asMorningStartTime12 = "21:15";
// const asMorningEndTime12 = "21:45";

// const asMorningStartTime13 = "22:15";
// const asMorningEndTime13 = "22:45";

const asEveningStartTime = "23:15";
const asEveningDayEndTime = "23:45";

// const asEveningDayEndTime = "23:59";

// const automaticSchedular = [
//   {
//     type: "automatic",
//     starttime: asMorningStartTime,
//     endtime: asMorningEndTime,
//     isrunning: 0
//   },
//   {
//     type: "automatic",
//     starttime: asMorningStartTime0,
//     endtime: asMorningEndTime0,
//     isrunning: 0
//   },
//   {
//     type: "automatic",
//     starttime: asMorningStartTime1,
//     endtime: asMorningEndTime1,
//     isrunning: 0
//   },
//   {
//     type: "automatic",
//     starttime: asMorningStartTime2,
//     endtime: asMorningEndTime2,
//     isrunning: 0
//   },
//   {
//     type: "automatic",
//     starttime: asMorningStartTime3,
//     endtime: asMorningEndTime3,
//     isrunning: 0
//   },
//   {
//     type: "automatic",
//     starttime: asMorningStartTime4,
//     endtime: asMorningEndTime4,
//     isrunning: 0
//   },
//   {
//     type: "automatic",
//     starttime: asMorningStartTime5,
//     endtime: asMorningEndTime5,
//     isrunning: 0
//   },
//   {
//     type: "automatic",
//     starttime: asMorningStartTime6,
//     endtime: asMorningEndTime6,
//     isrunning: 0
//   },
//   {
//     type: "automatic",
//     starttime: asMorningStartTime7,
//     endtime: asMorningEndTime7,
//     isrunning: 0
//   },
//   {
//     type: "automatic",
//     starttime: asMorningStartTime8,
//     endtime: asMorningEndTime8,
//     isrunning: 0
//   },
//   {
//     type: "automatic",
//     starttime: asMorningStartTime9,
//     endtime: asMorningEndTime9,
//     isrunning: 0
//   },
//   {
//     type: "automatic",
//     starttime: asMorningStartTime10,
//     endtime: asMorningEndTime10,
//     isrunning: 0
//   },
//   {
//     type: "automatic",
//     starttime: asMorningStartTime11,
//     endtime: asMorningEndTime11,
//     isrunning: 0
//   },
//   {
//     type: "automatic",
//     starttime: asMorningStartTime12,
//     endtime: asMorningEndTime12,
//     isrunning: 0
//   },
//   {
//     type: "automatic",
//     starttime: asMorningStartTime13,
//     endtime: asMorningEndTime13,
//     isrunning: 0
//   },
//   {
//     type: "automatic",
//     starttime: asEveningStartTime,
//     endtime: asEveningDayEndTime,
//     isrunning: 0
//   },
// ];

export default {
  name: "Scheduler",
  data() {
    return {
      loading: "Loading...",
      // schedulerData: [...automaticSchedular],
      schedulerData: [],
      interval: null,
      currentDateTime: null,
      isManualUpdateDisabled: true,
      isManualBtnDisabled: true
    };
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {
    this.get();

    this.interval = setInterval(() => {
      this.currentDateTime = moment()
        .utcOffset("-04:00")
        .format("MMM Do YYYY, H:mm:ss");

      // get latest running update

      this.get();

      // update automatic schedular
      this.updateAutomaticSchedular();

      // update button status
      this.updateManualSchedularBtn();
    }, 10000);
    setTimeout(() => (this.isManualBtnDisabled = false), 3000);
  },
  methods: {
    updateAutomaticSchedular() {
      const currentLT = moment()
        .utcOffset("-04:00")
        .format(timeFormat);
      const pmIndex = this.schedulerData.findIndex(
        val =>
          val.type == "automatic" &&
          currentLT > val.starttime &&
          currentLT < val.endtime
      );
      if (pmIndex > -1) {
        this.schedulerData[pmIndex].isrunning = 1;
      } else {
        const pmRunIndex = this.schedulerData.findIndex(
          val => val.type == "automatic" && val.isrunning == 1
        );
        if (pmRunIndex > -1) {
          this.schedulerData[pmRunIndex].isrunning = 0;
        }
      }
    },
    updateManualSchedularBtn() {
      const currentTime = moment()
        .utcOffset("-04:00")
        .format(timeFormat);

      const asmst = moment(asMorningStartTime, timeFormat)
        .subtract(1, "hours")
        .format(timeFormat);
      const asmet = moment(asMorningEndTime, timeFormat)
        .add(1, "hours")
        .format(timeFormat);
      const asest = moment(asEveningStartTime, timeFormat)
        .subtract(1, "hours")
        .format(timeFormat);

      if (
        (currentTime > asmst && currentTime < asmet) ||
        (currentTime > asest && currentTime < asEveningDayEndTime)
      ) {
        this.isManualUpdateDisabled = true;
      } else {
        this.isManualUpdateDisabled = false;
      }

      const msIndex = this.schedulerData.findIndex(
        val => val.type == "manual" && val.isrunning == 1
      );
      if (msIndex > -1) {
        this.isManualUpdateDisabled = true;
      }
    },
    get() {
      const todayDate = moment()
        .utcOffset("-04:00")
        .format("YYYY-MM-DD");
      this.$axios
        .get(`${this.$api}/scheduler`, { params: { todayDate } })
        .then(res => {
          if (!res.data.success) {
            this.loading = "No data found";
            return;
          }
          const data = res.data.data;
          const sdata = res.data.sdata;
          this.schedulerData = [];
          sdata.forEach(sd => {
            this.schedulerData.push(sd);
          });
          data.forEach(d => {
            const fIndex = this.schedulerData.findIndex(
              val => val.starttime == d.starttime && val.type == d.type
            );
            if (fIndex > -1) {
              if (this.schedulerData[fIndex].isrunning != d.isrunning) {
                this.schedulerData[fIndex].isrunning = d.isrunning;
                this.schedulerData[fIndex].endtime = d.endtime;
              }
            } else {
              this.schedulerData.push(d);
            }
          });
        })
        .catch(err => {
          console.log(err);
          this.loading = "No data found";
        });
    },
    manualUpdate() {
      if (this.isManualBtnDisabled || this.isManualUpdateDisabled) {
        return;
      }
      this.isManualBtnDisabled = true;
      this.$axios
        .post(`${this.$api}/scheduler`, {
          userId: localStorage.getItem("user-id")
        })
        .then(() => {
          setTimeout(() => (this.isManualBtnDisabled = false), 3000);
        })
        .catch(() => {
          setTimeout(() => (this.isManualBtnDisabled = false), 3000);
        });
    },
    getStatus(type, isrunning, endtime, starttime) {
      if (type == "automatic") {
        if (isrunning == 1) {
          return "Running...";
        }
        if (isrunning == 0) {
          const currentLT = moment()
            .utcOffset("-04:00")
            .format(timeFormat);
          // return endtime < currentLT ? "Finished" : "Scheduled";
          if (starttime <= currentLT && endtime >= currentLT) {
            return "Working";
          }
          if (starttime >= currentLT && endtime <= currentLT) {
            return "Finished";
          } else {
            return "Scheduled";
          }
          // if(starttime >= currentLT && endtime <= currentLT)
          // {
          //   return "Scheduled"
          // }

          // const currentLT = moment().utcOffset("-04:00").format(timeFormat)
          // if(starttime <= currentLT && endtime >= currentLT)
          // {
          //   return "Running...";
          // }
          // else
          // {
          //   return endtime;
          // }
          // console.log(currentLT)
          // console.log(endtime)
          // return starttime;
        }
      }
      if (type == "manual") {
        return isrunning == 1 ? "Running..." : "Finished";
      }
    }
  }
};
</script>
